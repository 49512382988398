import "./App.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import SideBar from "./components/layouts/SideBar/SideBar";
import { useEffect, useState } from "react";
import NewsPage from "./pages/NewsPage/NewsPage";
import { localStorageTheme } from "./constants/localStorageIs";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import NewsArticle from "./pages/NewsArticle/NewsArticle";
import OurProjectsPage from "./pages/OurProjectsPage/OurProjectsPage";
import PosterPage from "./pages/PosterPage/PosterPage";
import "@khmyznikov/pwa-install";
import { Modal } from "./components/layouts/Modal/Modal";
import { useRecoilState } from "recoil";
import { isOpenChat } from "./states/isOpenChat";
import { Chat } from "./components/Chat/Chat";
import Cookies from "./components/Cookies/Cookies";

function App() {
  const { pathname } = useLocation();
  const [isContactsPage, setIsContactsPage] = useState(false);
  const [isChatOpen, setIsChatOpen] = useRecoilState(isOpenChat);

  useEffect(() => {
    if (localStorage.getItem(localStorageTheme) === "dark") {
      document.body.classList.add("dark");
    }
    document.addEventListener("copy", (e) => {
      const selectedText = window.getSelection().toString();
      e.clipboardData.setData(
        "text/plain",
        `${selectedText} Источник: «Камчатка1» https://kamchatka1.tv`
      );
      e.preventDefault();
    });
  }, []);

  useEffect(() => {
    if (pathname.includes("/contacts")) {
      setIsContactsPage(true);
    }
  }, [pathname]);

  return (
    <div
      style={{ overflow: isContactsPage ? "hidden" : "visible" }}
      className={"page"}
    >
      <pwa-install
        install-description="Установить приложение Камчатка1?"
        description="Все новости камчатки в одном месте"
        name="Камчатка1"
      ></pwa-install>

      {isChatOpen && (
        <Modal onClose={() => setIsChatOpen(false)} isOpen={isChatOpen}>
          <Chat />
        </Modal>
      )}

      <SideBar />

      <div className={"page__content"}>
        <Routes>
          <Route exact path={"/"} element={<MainPage />} />
          <Route path={"/afisha"} element={<PosterPage />} />
          <Route path={"/afisha/:id"} element={<NewsArticle />} />
          <Route path={"/our-projects"} element={<OurProjectsPage />} />
          <Route path={"/news"} element={<NewsPage />} />
          <Route path={"/news/:id"} element={<NewsArticle />} />
          <Route path={"/contacts"} element={<ContactsPage />} />
        </Routes>
      </div>

      <div onClick={() => setIsChatOpen(true)} className={"ai-button"}>
        Нейросеть (VulcanoAI)
      </div>
      {!localStorage.getItem("cookieAccepted") && <Cookies />}
    </div>
  );
}

export default App;
