import './NewsArticle.scss';
import Header from "../../components/layouts/Header/Header";
import {useLocation, useParams, useNavigate} from "react-router-dom";
import ButtonWithIcon from "../../components/ButtonWithIcon/ButtonWithIcon";
import {regExFullImageStringNewEditor, regExImageNewEditor} from "../../helpers/regex";
import CustomButton from "../../components/CustomButton/CustomButton";
import {useEffect, useState} from "react";
import cn from "classnames";
import axios from "axios";
import {
    OKShareButton,
    TelegramShareButton,
    VKShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    OKIcon,
    TelegramIcon,
    VKIcon,
    WhatsappIcon,
} from "react-share";

const defaultImageUrl = '/images/default-image.jpg';
const shareIconSize = 32;

export default function NewsArticle() {

    const params = useParams();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const [currentNews, setCurrentNews] = useState();
    const [allNewsArray, setAllNewsArray] = useState([]);
    const [allTopNewsArray, setAllTopNewsArray] = useState([]);
    const isNews = pathname.includes('/news/');
    const isTopNews = pathname.includes('top');

    const [shareUrl, setShareUrl] = useState(window.location.href);

    useEffect(() => {
        setShareUrl(window.location.href)
        if (isNews) {
            if (isTopNews) {
                axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/tops/${params.id.slice(3)}?populate=*`)
                    .then(res => {
                        console.log(res.data.data)
                        setCurrentNews(res.data.data)
                    })

            } else {
                axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/news/${params.id}?populate=*`)
                    .then(res => setCurrentNews(res.data.data))

            }

            axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/tops?sort=id%3Adesc&pagination[limit]=9999`)
                .then(res => setAllTopNewsArray(res.data.data))
            axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/news?sort=id%3Adesc&pagination[limit]=9999`)
                .then(res => setAllNewsArray(res.data.data))
        } else {
            axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/posters/${params.id}`)
                .then(res => setCurrentNews(res.data.data))
            axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/posters?sort=id%3Adesc&pagination[limit]=9999`)
                .then(res => setAllNewsArray(res.data.data))
        }
    }, [pathname])

    if (!currentNews) return;

    //find new banner
    const banner = currentNews.attributes.banner?.data?.attributes.url;
    const fullContent = currentNews.attributes.content;

    let bannerUrl;
    let content;

    if (banner) {
        //get banner img
        bannerUrl = `${process.env.REACT_APP_PUBLIC_API}${banner}`

        //get content with many img
        content = fullContent.replaceAll('/uploads/', `${process.env.REACT_APP_PUBLIC_API}/uploads/`)
    } else {

        //get content with only one img
        const fullImageString = fullContent.match(regExFullImageStringNewEditor);
        content = fullImageString ? fullContent.replace(fullImageString[0], '') : fullContent;
    }


    const previewImage = fullContent.match(regExImageNewEditor)
        ? process.env.REACT_APP_PUBLIC_API + fullContent.match(regExImageNewEditor)[0]
        : defaultImageUrl;


    //get video
    let ruTubeLink = currentNews.attributes.link?.includes('/rutube.ru/');
    let currentVideoLink;
    if (currentNews.attributes.link?.includes('watch?v=')) {
        currentVideoLink = currentNews.attributes.link.split('watch?v=').slice(-1);
    } else if (currentNews.attributes.link?.includes('/youtu.be/')) {
        currentVideoLink = currentNews.attributes.link.split('/').slice(-1).join('');
    }

    const onNextPageClick = async (currentId) => {
        if (isNews) {
            if (isTopNews) {
                axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/tops?sort=id%3Adesc&pagination[limit]=9999`)
                    .then(res => {
                        const currentNewsIndex = res.data.data.findIndex(x => x.id === currentId);
                        return res.data.data[currentNewsIndex - 1].id;
                    })
                    .then((nextNewsId) => navigate(`/news/top${nextNewsId}`))
            } else {
                axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/news?sort=id%3Adesc&pagination[limit]=9999`)
                    .then(res => {
                        const currentNewsIndex = res.data.data.findIndex(x => x.id === currentId);
                        return res.data.data[currentNewsIndex - 1]?.id;
                    })
                    .then((nextNewsId) => {
                        if (nextNewsId) {
                            navigate(`/news/${nextNewsId}`)
                        } else {
                            navigate(`/news/top${allTopNewsArray[allTopNewsArray.length - 1]?.id.toString()}`)
                        }
                    })
            }
        } else {
            axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/posters?sort=id%3Adesc&pagination[limit]=9999`)
                .then(res => {
                    const currentNewsIndex = res.data.data.findIndex(x => x.id === currentId);
                    return res.data.data[currentNewsIndex - 1].id;
                })
                .then((nextNewsId) => navigate(`/afisha/${nextNewsId}`))
        }
    }

    const onPrevPageClick = (currentId) => {

        if (isNews) {

            if (isTopNews) {
                axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/tops?sort=id%3Adesc&pagination[limit]=9999`)
                    .then(res => {
                        const currentNewsIndex = res.data.data.findIndex(x => x.id === currentId);

                        return res.data.data[currentNewsIndex + 1]?.id;
                    })
                    .then((nextNewsId) => {
                        if (nextNewsId) {
                            navigate(`/news/top${nextNewsId}`)
                        } else {
                            navigate(`/news/${allNewsArray[0]?.id.toString()}`)
                        }
                    })
            } else {
                axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/news?sort=id%3Adesc&pagination[limit]=9999`)
                    .then(res => {
                        const currentNewsIndex = res.data.data.findIndex(x => x.id === currentId);
                        return res.data.data[currentNewsIndex + 1].id;
                    })
                    .then((nextNewsId) => {
                        if (allNewsArray[allNewsArray.length - 1]?.id.toString() === params.id) {
                            navigate(`/news/${allTopNewsArray[allTopNewsArray.length - 1]?.id.toString()}`)
                        } else {
                            navigate(`/news/${nextNewsId}`)
                        }
                    })
            }

        } else {
            axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/posters?sort=id%3Adesc&pagination[limit]=9999`)
                .then(res => {
                    const currentNewsIndex = res.data.data.findIndex(x => x.id === currentId);
                    return res.data.data[currentNewsIndex + 1].id;
                })
                .then((nextNewsId) => navigate(`/afisha/${nextNewsId}`))
        }
    }

    return (
        <div className={'article'}>
            <Header/>
            <div className={'article__main-wrap'}>
                <div className="article__media">
                    <img src={bannerUrl ? bannerUrl :previewImage} alt="Article preview"/>

                    {currentNews.attributes?.link &&
                    (
                        <div className="article__media-video">
                            {ruTubeLink ? (
                                <iframe
                                    width="720"
                                    height="405"
                                    src={`https://rutube.ru/play/embed/${currentNews.attributes.link.split('/').slice(-2, -1)}`}
                                    frameBorder="0"
                                    allow="clipboard-write; autoplay"
                                    webkitAllowFullScreen
                                    mozallowfullscreen='true'
                                    allowFullScreen
                                />
                            ) : (
                                <iframe
                                    src={`https://www.youtube.com/embed/${currentVideoLink}`}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            )}
                        </div>
                    )}

                </div>
                <div className="article__text-content article-text-content">

                    <ButtonWithIcon className={'article-text-content__head-button'}>
                        {pathname.includes('/news/') ? 'Читайте статью' : 'Афиша Камчатки'}
                    </ButtonWithIcon>

                    <div className="article__share-buttons">
                        Поделиться новостью:
                        <VKShareButton url={shareUrl}>
                            <VKIcon size={shareIconSize} round/>
                        </VKShareButton>
                        <TelegramShareButton url={shareUrl}>
                            <TelegramIcon size={shareIconSize} round/>
                        </TelegramShareButton>
                        <WhatsappShareButton url={shareUrl}>
                            <WhatsappIcon size={shareIconSize} round/>
                        </WhatsappShareButton>
                        <OKShareButton url={shareUrl}>
                            <OKIcon size={shareIconSize} round/>
                        </OKShareButton>
                    </div>

                    <div className="article-text-content__main">
                        <div className="article-text-content__data">{currentNews.attributes.date}</div>
                        {content && <div dangerouslySetInnerHTML={{__html: content}}/>}
                    </div>

                    <div className="article-text-content__navigations">
                        <CustomButton
                            background={'#EE535A'}
                            className={cn('sidebar__poster-button',
                                {
                                    'ghost-button': allNewsArray[allNewsArray.length - 1]?.id.toString() === params.id
                                    // 'ghost-button': isTopNews
                                    //     ? allTopNewsArray[allTopNewsArray.length - 1]?.id.toString() === params.id.slice(3)
                                    //     : allNewsArray[allNewsArray.length - 1]?.id.toString() === params.id
                                }
                            )}
                            link={`#`}
                            onClick={() => onPrevPageClick(currentNews.id)}
                        >
                            Предыдущая
                        </CustomButton>
                        <CustomButton
                            background={'linear-gradient(92.17deg, #2B82ED 0.57%, #559CF3 100%)'}
                            className={cn('sidebar__poster-button',
                                {
                                    'ghost-button': allTopNewsArray[0]?.id.toString() === params.id.slice(3)
                                    // 'ghost-button': isTopNews
                                    //     ? allTopNewsArray[0]?.id.toString() === params.id.slice(3)
                                    //     : allNewsArray[0]?.id.toString() === params.id
                                }
                            )}
                            link={`#`}
                            onClick={() => onNextPageClick(currentNews.id)}
                        >
                            Следующая
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    )
}
