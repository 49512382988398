import './NewsCard.scss';
import {Link} from "react-router-dom";
import cn from "classnames";
import {regExImageNewEditor} from "../../helpers/regex";

const defaultImageUrl = '/images/default-image.jpg';

export default function NewsCard({minimum, card, banner, id, posters}) {

    const imgReg = card.content.match(regExImageNewEditor);
    const slicedDescription = `${card?.description.slice(0, 103)}...`;
    let previewImage;

    if (imgReg === null) {
        previewImage = defaultImageUrl
    } else {
        previewImage = process.env.REACT_APP_PUBLIC_API + card.content.match(regExImageNewEditor)[0];
    }

    return (
        <div className={cn('news-card', {'news-card__minimum': minimum, 'news-card_top-news': card.top})}>

            <div>
                <div className="news-card__img-wrap">
                    <img src={banner ? banner : `${previewImage}`} alt="News preview"/>

                    {!minimum && card.date && <span>{card.date}</span>}
                </div>

                <div className="news-card__info">

                    {/*{minimum && <div className="news-card__min-data">{date}</div>}*/}

                    <h3>{card.title}</h3>

                    {!minimum && (
                        <p>
                            {slicedDescription}
                        </p>
                    )}
                    {minimum && (
                        <Link
                            to={`/${posters ? 'afisha/' : 'news/'}${card.top ? 'top' : ''}${id}`}
                            className="news-card__link"
                        >
                            Подробнее →
                        </Link>
                    )}
                </div>
            </div>

            {!minimum && (
                <Link to={`/${posters ? 'afisha/' : 'news/'}${card.top ? 'top' : ''}${id}`} className="news-card__link">
                    Подробнее →
                </Link>
            )}
        </div>
    )
}