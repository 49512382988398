import {useEffect, useState} from "react";
import axios from "axios";

export const useNews = (page) => {

    const [news, setNews] = useState([]);

    useEffect(() => {
        Promise.all([
            axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/tops?sort=id%3Adesc&populate=*`),
            axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/news?sort=id%3Adesc&pagination[page]=${page ? page : 1}&populate=*`)
        ]).then((res) => {
            res.forEach((item, i) => {
                setNews(news => [...news, ...res[i].data.data])
            })
        })
    }, [])

    return news;
}