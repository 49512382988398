import './MobileMenuButton.scss';
import {useRecoilState} from "recoil";
import {isVisibleState} from "../../SideBar/isVisibleState";
import cn from "classnames";

export default function MobileMenuButton({className}) {

    const [isSidebarVisible, setIsSidebarVisible] = useRecoilState(isVisibleState);

    return (
        <div onClick={() => setIsSidebarVisible(!isSidebarVisible)} className={cn('mobile-menu-button', className)}>
            <span>МЕНЮ</span>
            <div
                className={cn('mobile-menu-button__burger-wrap', {'mobile-menu-button__burger-wrap_active': isSidebarVisible})}
            />
        </div>
    )
}