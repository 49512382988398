import './LatestNews.scss';
import NewsCard from "../../../components/NewsCard/NewsCard";
import ButtonWithIcon from "../../../components/ButtonWithIcon/ButtonWithIcon";
import cn from "classnames";
import {useNews} from "../../../hooks/useNews";
import {Link} from "react-router-dom";
import {useIsMobile} from "../../../hooks/useIsMobile";
import {useRef} from "react";

export default function LatestNews({className}) {

    const latestNewsRef = useRef();
    const isMobile = useIsMobile();
    const news = useNews();

    const newsList = (isMobile ? news.slice(0, 4) : news).map((item) => {

        const img = item.attributes.banner?.data?.attributes.url;
        let bannerUrl;

        if (img) {
            bannerUrl = `${process.env.REACT_APP_PUBLIC_API}${item.attributes.banner.data.attributes.url}`
        }

        return (
            <NewsCard
                key={item.id}
                id={item.id}
                minimum={true}
                card={item.attributes}
                banner={bannerUrl}
            />
        )
    });

    return (
        <div className={cn('latest-news', className)}>
            <Link to={'/news'}>
                <ButtonWithIcon>Последние новости</ButtonWithIcon>
            </Link>
            <div ref={latestNewsRef}
                 style={isMobile ? {maxHeight: 'max-content'} : {height: `calc(100vh - ${latestNewsRef.current?.getBoundingClientRect().top + 16}px)`}}
                 className={"latest-news__news-list"}>
                {newsList}
            </div>
        </div>
    )
}