import './MainPage.scss';
import TvOnline from "../../components/TvOnline/TvOnline";
import LatestNews from "./LatestNews/LatestNews";
import Weather from "./Weather/Weather";
import RunningLine from "../../components/RuningLine/RunningLine";
import Radio from "../../components/Radio/Radio";
import HeaderOptional from "../../components/layouts/Header/HeaderOptional/HeaderOptional";
import {Link} from "react-router-dom";
import MobileMenuButton from "../../components/layouts/Header/MobileMenuButton/MobileMenuButton";
import {useEffect, useState} from "react";
import axios from "axios";
import Slider from "react-slick";
import Currencies from "./Currencies/Currencies";

export default function MainPage() {

    const [banners, setBanners] = useState([]);
    const isDesktopOrLess = window.matchMedia('(max-width: 1435px)').matches;
    const isTablet = window.matchMedia('(min-width: 768px) and (max-width: 1435px)').matches;
    const isTabletOrLess = window.matchMedia('(max-width: 768px)').matches;

    useEffect(() => {
        axios.get('https://api.kamchatka1.tv/api/banners?populate=*')
            .then(res => setBanners(res.data.data))
    }, [])

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 600,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        swipeToSlide: true,
        initialSlide: 0,
        appendDots: dots => (
            <div className={'my-slider__nav-wrap'}>
                <ul>{dots}</ul>
            </div>
        ),
    };

    const bannersList = banners.map(item => {
        const image = item.attributes.banner.data?.attributes.url;
        const link = item.attributes.url;

        return (
            link ? (
                <a className={'main-widgets__banner-inner_wrap'} key={item.id} href={link} target={'_blank'}
                   rel={'noreferrer'}>
                    <img
                        src={`${process.env.REACT_APP_PUBLIC_API}${image}`}
                        className={'bordered'}
                        alt="Banner"
                    />
                </a>
            ) : (
                <div className={'main-widgets__banner-inner_wrap'} key={item.id}>
                    <img
                        src={`${process.env.REACT_APP_PUBLIC_API}${image}`}
                        className={'bordered'}
                        alt="Banner"
                    />
                </div>
            )
        )
    });

    return (
        <>
            <main className="main-page__main">
                <div className="main-page__middle">
                    <div className="main-page__mobile-header main-mobile-header">

                        <div className="main-mobile-header__inner-wrap">
                            <RunningLine className={'main-mobile-header__running-line'}/>
                            <MobileMenuButton className={'main-mobile-header__mobile-button'}/>
                        </div>

                        <div className="main-mobile-header__inner-wrap_down">
                            <Link className={'main-mobile-header__logo-wrap'} to={'/'}>
                                <img src="/images/cum-new-logo.svg" alt="Kamchatka1 tv"/>
                            </Link>

                            {isTablet && <Radio className={'main-mobile-header__radio'}/>}

                            <HeaderOptional className={'main-mobile-header__optionals'}/>
                            <MobileMenuButton className={'main-mobile-header__mobile-button_second'}/>
                        </div>

                    </div>

                    <LatestNews className={'main-page__mobile-news'}/>

                    <TvOnline/>

                    <div className={'main-page__widgets main-widgets'}>
                        {isTabletOrLess && <Radio className={'main-widgets__mobile-radio'}/>}
                        <div className="main-widgets__weather">
                            <Weather/>
                        </div>
                        <div className="main-widgets__banner-wrap">
                            <div className="main-widgets__rates">
                                <Currencies/>
                            </div>
                            <div className="main-widgets__banner">
                                <div className="main-widgets__banner-inner">
                                    <Slider{...settings}>
                                        {bannersList}
                                    </Slider>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <aside className="main-page__latest-news">

                    <div className="main-page__latest-news-upper-wrap">
                        {!isDesktopOrLess && <Radio/>}
                        <HeaderOptional/>
                    </div>

                    <LatestNews/>

                </aside>

            </main>
        </>
    )
}