export const projects = [
    {
        link: 'https://www.youtube.com/playlist?list=PLVSWfSy_qHYZ1qBzKaql1QD-CDxfKNvwu',
        title: 'Своя правда',
        text: 'Если вам кажется, что помощи ждать неоткуда, вы зашли в «бюрократический» тупик и у вас просто опускаются руки, то вам просто необходимо обратиться в программу «Своя правда»',
        image: '/images/ourProjects/my-true.jpg'
    },
    {
        link: 'https://www.youtube.com/playlist?list=PLVSWfSy_qHYb3A_hHC2ucNcQxxdPUObZg',
        title: 'Своя правда Live',
        text: 'Еженедельные встречи в прямом эфире с теми, кого ждали жители полуострова. Неудобные вопросы, жаркие обсуждения, хлесткие комментарии подписчиков и реальные эмоции.',
        image: '/images/ourProjects/myTrueLive.png'
    },
    {
        link: 'https://www.youtube.com/playlist?list=PLVSWfSy_qHYbWOMrzPX9HDstwj4K81Tau',
        title: 'Мама, я жду тебя',
        text: 'Самый трогательный проект на камчатском телевидении. Детки, оставшиеся без родителей, но очень надеющиеся вновь почувствовать семейное тепло, встречаются с ведущей проекта Валерией Васенькиной.',
        image: '/images/ourProjects/mama-waiting.png'
    },
    {
        link: 'https://www.youtube.com/playlist?list=PLVSWfSy_qHYZF92KYH_FipYN_xkXzqlCZ',
        title: 'ОТЦЫ и ЭТИ',
        text: 'Как часто взрослые недовольны молодежью... Как часто молодое поколение жалуется на то, что их не понимают взрослые…',
        image: '/images/ourProjects/fatherandit.jpg'
    },
    {
        link:'https://www.youtube.com/playlist?list=PLVSWfSy_qHYY74wUJKZ4zndP0ekS8i1T3',
        title: 'Гость в студии',
        text: 'Эфиры с приглашенными гостями!',
        image: '/images/ourProjects/gostizvne.jpg'
    },
    {
        link: 'https://www.youtube.com/playlist?list=PLVSWfSy_qHYatpce5WAXYAcKUCgbDB5N8',
        title: 'НАКИПЕЛО',
        text: 'Обсуждаем самые обсуждаемые темы нашего края',
        image: '/images/ourProjects/nakipelo.jpg'
    },
    {
        link: 'https://www.youtube.com/playlist?list=PLVSWfSy_qHYarIiEkDjSeIjmh5XD1UWii',
        title: 'КИПЯТОК ШОУ',
        text: '',
        image: '/images/ourProjects/kipytokshow.jpg'
    },
    {
        link: 'https://www.youtube.com/playlist?list=PLVSWfSy_qHYY4uRqn4zhHczd93HlEPr8q',
        title: 'ПРО СПОРТ',
        text: 'Все про спорт с Викторией Степашко',
        image: '/images/ourProjects/prosport.jpg'
    },
    {
        link: 'https://www.youtube.com/playlist?list=PLVSWfSy_qHYbUK-AGZmHhP4UC64-GUT47',
        title: 'ЭКСТРЕННЫЙ ВЫЗОВ',
        text: '',
        image: '/images/ourProjects/mchs.jpg'
    },
    {
        link: 'https://www.youtube.com/playlist?list=PLVSWfSy_qHYZ0Sy-5v-FB3v4BXZiQLyIv',
        title: 'ИТОГИ ДНЯ',
        text: '',
        image: '/images/ourProjects/itogiday.jpg'
    },
    {
        link: 'https://www.youtube.com/playlist?list=PLVSWfSy_qHYbC2p2oqnGWuQmU-ZJJH0Xj',
        title: 'СОБЫТИЯ НЕДЕЛИ',
        text: '',
        image: '/images/ourProjects/daynow.jpg'

    }
]