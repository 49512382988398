import React from "react";
import CustomButton from "../CustomButton/CustomButton";
import "./Cookies.scss";
import { useRecoilValue } from "recoil";

const Cookies = () => {
  // const isDarkMode = useRecoilValue(isDarkMode);

  const onClickHandler = () => {
    localStorage.setItem("cookieAccepted", true);
  };

  return (
    <div className="cookies">
      <div className="cookies__content">
        <p className="cookies__text">Мы используем cookie-файлы</p>
        <CustomButton
          background={"linear-gradient(92.17deg, #2B82ED 0.57%, #559CF3 100%)"}
          onClick={() => onClickHandler()}
          className={"cookies__button"}
        >
          Хорошо
        </CustomButton>
      </div>
    </div>
  );
};

export default Cookies;
