import "./ContactsPage.scss";
import Header from "../../components/layouts/Header/Header";
import CustomButton from "../../components/CustomButton/CustomButton";
import { ReactComponent as PhoneIcon } from "../../icons/phone.svg";
import { ReactComponent as UserIcon } from "../../icons/user.svg";
import { useEffect, useState } from "react";
import ContactData from "./ContactData/ContactData";
import { ReactComponent as ArrowUpIcon } from "../../icons/arrow-up.svg";
import cn from "classnames";
import pdf from "../../constants/rull.pdf";

const initialPosition = -380;
const finishPosition = 0;
const maxMobileWidth = 576;
const initialFormInputs = {
  name: "",
  email: "",
  textarea: "",
};
const contacts = [
  {
    icon: <PhoneIcon />,
    specialLink: "+74152266265",
    text: "+7 415 226-62-65",
  },
  {
    icon: <UserIcon />,
    text: "ул. Академика Королёва, 29/1, Петропавловск-Камчатский, Камчатский край, 683009",
  },
];

export default function ContactsPage() {
  const [startCoordsY, setStartCoordsY] = useState();
  const [position, setPosition] = useState(initialPosition);
  const [isMoving, setIsMoving] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [inputs, setInputs] = useState(initialFormInputs);

  const contactsList = contacts.map(({ icon, specialLink, text }) => {
    return (
      <ContactData
        key={text}
        icon={icon}
        specialLink={specialLink}
        text={text}
      />
    );
  });

  useEffect(() => {
    const onMouseMove = (event) => {
      if (isOpened) {
        setPosition(startCoordsY - event.screenY);
      } else {
        setPosition(startCoordsY - event.screenY + initialPosition);
      }
    };
    const onTouchMove = (event) => {
      if (isOpened) {
        setPosition(startCoordsY - event.changedTouches[0].screenY);
      } else {
        setPosition(
          startCoordsY - event.changedTouches[0].screenY + initialPosition
        );
      }
    };

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("touchmove", onTouchMove);
    };
  }, [isMoving]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputs({ ...inputs, [name]: value });
  };

  const sendEmail = () => {};
  const onTouchMove = (event) => {
    if (isOpened) {
      setPosition(startCoordsY - event.changedTouches[0].screenY);
    } else {
      setPosition(startCoordsY - event.touches[0].screenY + initialPosition);
    }
  };

  const onTouchStart = (event) => {
    setIsMoving(true);
    setStartCoordsY(event.changedTouches[0].screenY);
  };

  const onTouchEnd = (event) => {
    setIsMoving(false);

    if (startCoordsY === event.changedTouches[0].screenY) {
      if (isOpened) {
        setPosition(initialPosition);
        setIsOpened(false);
      } else {
        setPosition(finishPosition);
        setIsOpened(true);
      }
    } else {
      if (isOpened) {
        if (event.changedTouches[0].screenY - startCoordsY >= 110) {
          setPosition(initialPosition);
          setIsOpened(false);
        } else {
          setPosition(finishPosition);
        }
      } else {
        if (startCoordsY - event.changedTouches[0].screenY >= 110) {
          setPosition(finishPosition);
          setIsOpened(true);
        } else {
          setPosition(initialPosition);
        }
      }
    }
  };

  return (
    <main className={"contacts-page"}>
      <Header />

      <div className="contacts-page__map-wrap">
        <div className="contacts-page__map">
          <iframe
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A43b86d2b1adf2a13a8cc669d01365a544910f0aa53935a2052968837b5c24227&amp;source=constructor"
            width="100%"
            frameBorder={0}
          />
        </div>

        <div
          style={{
            bottom: `${position}px`,
            transition: isMoving ? "none" : "bottom 0.3s ease-in-out",
            zIndex: position === initialPosition ? "1" : "1200",
          }}
          className="contacts-page__main-info"
        >
          <div
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onTouchMove={onTouchMove}
            className={cn("contacts-page__drag-arrow", {
              "contacts-page__drag-arrow_rotated": isOpened,
            })}
          >
            <ArrowUpIcon />
          </div>

          <form className={"contacts-page__form contacts-form"}>
            <div className="contacts-form__mobile-outer-wrap">
              <div className="contacts-page__main-info-wrap">
                <h2 className={"contacts-page__main-info-title"}>
                  Наши Контакты
                </h2>
                {contactsList}
                <p className={"contacts-page__notice"}>
                  {new Date().getFullYear()} © ООО "Масс-Медиа Центр
                </p>
                <ContactData
                  className="contact-data"
                  icon={null}
                  specialLink={null}
                  text={
                    "СМИ - Телеканал, св-во о регистрации: серия Эл №ФС77-79584 от 27 ноября 2020 года."
                  }
                />
              </div>

              {/* <div className="contacts-form__mobile-inner-wrap">
                <h2 className={"contacts-form__title"}>Связаться с нами</h2>

                <input
                  name={"name"}
                  type="text"
                  placeholder={"Имя"}
                  value={inputs.name}
                  onChange={handleChange}
                />
                <input
                  name={"email"}
                  type="email"
                  placeholder={"Ваша почта"}
                  value={inputs.email}
                  onChange={handleChange}
                />
                <textarea
                  name={"textarea"}
                  placeholder={"Введите сообщение"}
                  value={inputs.textarea}
                  onChange={handleChange}
                />
              </div> */}
            </div>
            <CustomButton
              background={
                "linear-gradient(92.17deg, #2B82ED 0.57%, #559CF3 100%)"
              }
              className={"contacts-form__send-button"}
              href={"https://t.me/News_Bot_Kam"}
            >
              Связаться с нами
            </CustomButton>
            {/* <CustomButton
              background={
                "linear-gradient(92.17deg, #2B82ED 0.57%, #559CF3 100%)"
              }
              className={"contacts-form__send-button"}
              onClick={sendEmail}
              href={"#"}
            >
              Отправить сообращение
            </CustomButton> */}

            <div className="contacts-form__politic">
              нажимая на кнопку вы соглашаетесь
              <br /> с{" "}
              <a href={pdf} target="_blank">
                политикой конфиденциальности
              </a>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
