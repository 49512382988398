import './OurProjectsPage.scss';
import Header from "../../components/layouts/Header/Header";
import OurProjectCard from "./OurProjectCard/OurProjectCard";
import {projects} from "./projectsArray";
import {useRecoilValue} from "recoil";
import {isDarkModeState} from "./darkModeState";

export default function OurProjectsPage() {

    const isDarkMode = useRecoilValue(isDarkModeState);

    const projectsList = projects.map(item => {
        return (
            <OurProjectCard
                key={item.title}
                card={item}
            />
        )
    })

    return (
        <div className={'our-projects'}>
            <Header/>
            <h1>Ведущий телеканал в Камчатском крае</h1>
            <p>
                Холдинг «Масс Медиа Центр» — более 15 лет на рынке рекламы и СМИ. В состав холдинга входят одни из самых
                известных и популярных средств массовой информации края: телеканал «Камчатка-1», «Радио Дача», «Лав
                Радио», «Хит ФМ», «Наше радио», «Юмор ФМ», «Радио Энержди», а также информационное агентство «Масс Медиа
                News».
                «<span>Масс Медиа Центр</span>» – вступай в новостное движение!
            </p>
            <h2>Проекты «МАСС МЕДИА NEWS»</h2>
            <div className="our-projects__grid">
                <div className="our-projects__grid-media">
                    <img src={isDarkMode ? '/images/ourProjects/mass-media.png' : '/images/ourProjects/mass-media-dark.png'} alt="Масс медиа"/>
                </div>
                {projectsList}
            </div>
        </div>
    )
}