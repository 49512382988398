import "./SideBar.scss";
import Search from "../../Search/Search";
import { Link, NavLink, useLocation } from "react-router-dom";
import { navigations } from "./navigations";
import CustomButton from "../../CustomButton/CustomButton";
import { useRecoilState, useSetRecoilState } from "recoil";
import { isVisibleState } from "./isVisibleState";
import cn from "classnames";
import { useEffect } from "react";
import { ReactComponent as Newspaper } from "../../../icons/newspaper.svg";
import { isOpenChat } from "../../../states/isOpenChat";
import pdf from "../../../constants/rull.pdf";

const stopScrollClass = "stop-scroll";

export default function SideBar() {
  const { pathname } = useLocation();
  const setIsChatOpen = useSetRecoilState(isOpenChat);
  const [isSidebarVisible, setIsSidebarVisible] =
    useRecoilState(isVisibleState);
  const scrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth;

  useEffect(() => {
    setIsSidebarVisible(false);
  }, [pathname]);

  useEffect(() => {
    if (isSidebarVisible) {
      document.body.classList.add(stopScrollClass);
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      setTimeout(() => {
        document.body.classList.remove(stopScrollClass);
        document.body.style.paddingRight = `0`;
      }, 200);
    }
  }, [isSidebarVisible]);

  useEffect(() => {
    const onEscape = ({ key }) => {
      if (key === "Escape") {
        setIsSidebarVisible(false);
      }
    };

    document.addEventListener("keydown", onEscape);
    return () => document.removeEventListener("keydown", onEscape);
  }, []);

  const navigationList = navigations.map((item) => {
    return (
      <li className={"sidebar-nav-wrap__list-item"} key={item.name}>
        <NavLink
          to={item.url}
          end
          className={({ isActive }) =>
            isActive
              ? "sidebar-nav-wrap__list-link sidebar-nav-wrap__list-link_active"
              : "sidebar-nav-wrap__list-link"
          }
        >
          {item.icon}
          {item.name}
        </NavLink>
      </li>
    );
  });

  return (
    <div
      className={cn("sidebar-wrap", {
        "sidebar-wrap_active": isSidebarVisible,
      })}
    >
      <aside className={cn("sidebar", { sidebar_visible: isSidebarVisible })}>
        <div className="sidebar__inner">
          <div className="sidebar__inner-upper">
            <Link to={"/"}>
              <img
                className={"sidebar__logo"}
                src="/images/cum-new-logo.svg"
                alt="Kamchatka1 tv"
              />
            </Link>
            <Search
              className={"sidebar__search"}
              placeholder={"Поиск новостей"}
            />
            <CustomButton
              background={
                "linear-gradient(92.17deg, #2B82ED 0.57%, #559CF3 100%)"
              }
              className={"sidebar__poster-button"}
              link={"https://kam-koncert.ru/"}
            >
              Афиша Камчатки
            </CustomButton>
            <div className="sidebar__nav-wrap sidebar-nav-wrap">
              <h4>Главное меню</h4>
              <nav>
                <ul className={"sidebar-nav-wrap__list"}>
                  {navigationList}

                  {/*<li onClick={() => setIsChatOpen(true)} className={'sidebar-nav-wrap__list-item'}>*/}
                  {/*    <div className={'sidebar-nav-wrap__list-link'}>*/}
                  {/*        <Newspaper style={{transform: 'rotate(180deg)'}}/>*/}
                  {/*        VulcanoAI*/}
                  {/*    </div>*/}
                  {/*</li>*/}
                </ul>
              </nav>
            </div>
          </div>

          <div className="sidebar__inner-down">
            <div className="sidebar__add-news">
              <img src="/images/Micro.png" alt="Microphone" />
              <CustomButton
                background={
                  "linear-gradient(92.17deg, #2B82ED 0.57%, #559CF3 100%)"
                }
                href={"https://t.me/News_Bot_Kam"}
              >
                Сообщить новость
              </CustomButton>
            </div>
          </div>

          {/*<a*/}
          {/*    className={'sidebar__informer'}*/}
          {/*    href="https://metrika.yandex.ru/stat/?id=71175085&amp;from=informer"*/}
          {/*    target="_blank"*/}
          {/*    rel="nofollow"*/}
          {/*>*/}
          {/*    <img*/}
          {/*        src="https://informer.yandex.ru/informer/71175085/3_1_FFFFFFFF_EFEFEFFF_0_pageviews"*/}
          {/*        style={{*/}
          {/*            width: '88px',*/}
          {/*            height: '31px',*/}
          {/*            border: '0'*/}
          {/*        }}*/}
          {/*        alt="Яндекс.Метрика"*/}
          {/*        title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)"*/}
          {/*    />*/}
          {/*</a>*/}

          <a
            className={"sidebar__informer"}
            href="https://metrika.yandex.ru/stat/?id=71175085&amp;from=informer"
            target="_blank"
            rel="nofollow"
          >
            <img
              src="https://informer.yandex.ru/informer/71175085/3_1_FFFFFFFF_EFEFEFFF_0_pageviews"
              style={{ width: "88px", height: "31px", border: "0" }}
              alt="Яндекс.Метрика"
              title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)"
            />
          </a>
          <a className="policy" href={pdf} target="_blank">
            Политика конфиденциальности
          </a>
        </div>
      </aside>
      <div
        onClick={() => setIsSidebarVisible(false)}
        className={cn("sidebar__overlay", {
          sidebar__overlay_visible: isSidebarVisible,
        })}
      />
    </div>
  );
}
