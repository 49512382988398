import './OurProjectCard.scss';
import {useRef, useState} from "react";
import {useIsMobile} from "../../../hooks/useIsMobile";
import cn from "classnames";

export default function OurProjectCard({card}) {

    const textRef = useRef();
    const [isHover, setIsHover] = useState(false);
    const isMobile = useIsMobile();

    return (
        <div
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={() => setIsHover(!isHover)}
            className={cn('our-projects-card', {'our-projects-card_shadow': isHover || isMobile})}
        >
            <img className={'our-projects-card__back-image'} src={card.image} alt="Card background"/>
            <a href={card.link} target={'_blank'} rel={'noreferrer'} className="our-projects-card__notice">
                Смотреть выпуск
            </a>
            <h3>{card.title}</h3>
            <div style={{height: isHover || isMobile ? textRef.current?.clientHeight : '0px'}} className="our-projects-card__text-wrap">
                <p ref={textRef}>
                    {card.text}
                </p>
            </div>
        </div>
    )
}