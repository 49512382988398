import './Search.scss';
import {ReactComponent as LoopIcon} from "../../icons/loop.svg";
import cn from "classnames";
import {useEffect, useRef, useState} from "react";
import {useNews} from "../../hooks/useNews";
import {Link, useParams} from "react-router-dom";

export default function Search({placeholder, className}) {

    const {pathname} = useParams();
    const inputRef = useRef(null);
    const news = useNews();
    const [inputValue, setInputValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isResultsVisible, setIsResultsVisible] = useState(false);

    useEffect(() => {
        setIsResultsVisible(false);
    }, [pathname])

    useEffect(() => {

        const onFreeSpaceClick = () => setIsResultsVisible(false);

        window.addEventListener('click', onFreeSpaceClick);

        return () => window.removeEventListener('click', onFreeSpaceClick);
    }, [])

    const OnInputChange = (e) => {
        setInputValue(e.target.value)
        let result = [];
        if (e.target.value.trim().length > 0) {
            result = news.filter((item) => {
                const input = e.target.value.toLowerCase().trim();
                const title = item.attributes.title.toLowerCase().replace(' ', '');
                return title.includes(input)
            })
        }
        setSearchResults(result)
    }

    const resultList = searchResults?.map((item) => {

        return (
            <Link
                key={item.attributes.title + item.attributes.createdAt}
                className={'search-result__item'}
                to={`/news/${item.id}`}
            >
                <div className="search-result__item-inner">
                    {item.attributes.title}
                </div>
            </Link>
        )
    })

    return (
        <div className={cn('search', className)}>
            <div className="search__input-wrap">
                <LoopIcon/>
                <input
                    onClick={(e) => {
                        e.stopPropagation()
                        setIsResultsVisible(true)
                    }}
                    ref={inputRef}
                    type="text"
                    value={inputValue}
                    onChange={OnInputChange}
                    className="search__input"
                    placeholder={placeholder}
                />
            </div>

            {isResultsVisible && searchResults.length ? (
                <div
                    style={{paddingTop: isResultsVisible ? '23px' : '0px'}}
                    className={'search__result search-result'}
                >
                    <div className={'search-result__list'}>
                        {resultList}
                    </div>
                </div>
            ) : null}

        </div>
    )
}