import {ReactComponent as Desktop} from "../../../icons/desktop.svg";
import {ReactComponent as Newspaper} from "../../../icons/newspaper.svg";
import {ReactComponent as Face} from "../../../icons/face.svg";
import {ReactComponent as Chain} from "../../../icons/chain.svg";

export const navigations = [
    {
        name: 'Главная страница',
        url: '/',
        icon: <Desktop/>
    },
    {
        name: 'Новости Камчатки',
        url: '/news',
        icon: <Newspaper/>
    },
    {
        name: 'Наши проекты',
        url: '/our-projects',
        icon: <Face/>
    },
    {
        name: 'Контакты',
        url: '/contacts',
        icon: <Chain/>
    }
]