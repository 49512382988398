import './TvOnline.scss';

export default function TvOnline() {

    return (
        <div className="television">
            <iframe
                src={'https://tv.allkam.ru/kamchatka1/embed.html?autoplay=0'}
                title="Kamchatka1 tv"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
            />
        </div>
    )
}