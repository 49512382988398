import './NewsPage.scss';
import Search from "../../components/Search/Search";
import NewsCard from "../../components/NewsCard/NewsCard";
import Header from "../../components/layouts/Header/Header";
import {useEffect, useState} from "react";
import axios from "axios";
import CustomButton from "../../components/CustomButton/CustomButton";
import {currentPageState} from "../../states/currentPageState";
import {useRecoilState} from "recoil";

export default function NewsPage() {

    const [page, setPage] = useRecoilState(currentPageState);
    const [newsInView, setNewsInView] = useState([]);
    const [topNews, setTopNews] = useState([]);
    const [totalNewsLength, setTotalNewsLength] = useState(0);
    const [isAllNewsInView, setIsAllNewsInView] = useState(false);

    const newsList = [...topNews, ...newsInView].map((item) => {
        const img = item.attributes.banner?.data?.attributes.url;
        let bannerUrl;

        if (img) {
            bannerUrl = `${process.env.REACT_APP_PUBLIC_API}${item.attributes.banner.data.attributes.url}`
        }

        return (
            <NewsCard
                key={item.id}
                card={item.attributes}
                banner={bannerUrl}
                id={item.id}
            />
        )
    });

    useEffect(() => {
        setIsAllNewsInView(newsInView.length >= totalNewsLength);
    }, [newsInView])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/tops?populate=*`)
            .then(res => setTopNews(res.data.data))
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/news?sort=id%3Adesc&pagination[page]=${page}&populate=*`)
            .then(res => {
                if (page > 1) {
                    setNewsInView(newsInView => [...newsInView, ...res.data.data])
                } else {
                    setTotalNewsLength(res.data.meta.pagination.total)
                    setNewsInView(res.data.data)
                }
            })
    }, [page])

    return (
        <main className={'news-page'}>
            <Header/>
            <Search placeholder={'Найти новость'} className={'news-page__search'}/>
            <div className="news-page__grid">
                {newsList}
            </div>
            <br/>
            {!isAllNewsInView && (
                <CustomButton
                    background={'linear-gradient(92.17deg, #2B82ED 0.57%, #559CF3 100%)'}
                    onClick={() => setPage(page + 1)}
                >
                    Показать еще
                </CustomButton>
            )}
        </main>
    )
}