import './Header.scss';
import RunningLine from "../../RuningLine/RunningLine";
import cn from "classnames";
import Radio from "../../Radio/Radio";
import HeaderOptional from "./HeaderOptional/HeaderOptional";
import MobileMenuButton from "./MobileMenuButton/MobileMenuButton";
import {Link} from "react-router-dom";

export default function Header({className}) {
    const isDesktopOrLess = window.matchMedia("(max-width: 1435px)").matches;
    const isTablet = window.matchMedia('(min-width: 768px) and (max-width: 1435px)').matches;
    return (
        <header className={cn('header', className)}>

            <div className="main-page__mobile-header main-mobile-header">

                <div className="main-mobile-header__inner-wrap">
                    <RunningLine className={'main-mobile-header__running-line'}/>
                    <MobileMenuButton className={'main-mobile-header__mobile-button'}/>
                </div>

                <div className="main-mobile-header__inner-wrap_down">
                    <Link className={'main-mobile-header__logo-wrap'} to={'/'}>
                        <img src="/images/cum-new-logo.svg" alt="Kamchatka1 tv"/>
                    </Link>

                    {isTablet && <Radio className={'main-mobile-header__radio'}/>}

                    <HeaderOptional className={'main-mobile-header__optionals'}/>
                    <MobileMenuButton className={'main-mobile-header__mobile-button_second'}/>
                </div>
            </div>

            <div>
                <div className="main-page__latest-news-upper-wrap">
                    {!isDesktopOrLess && <Radio/>}
                    <HeaderOptional/>
                </div>
            </div>

        </header>
    )
}