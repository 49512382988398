import './CustomButton.scss';
import cn from 'classnames'
import {Link} from "react-router-dom";

export default function CustomButton({className, background, onClick, link, href, children}) {

    return (
        <>
            {href ? (
                <a style={{background: background ? background : '#EE5259'}} href={href} onClick={() => onClick && onClick()} className={cn('custom-button', className)}>
                    <div className="custom-button__inner">
                        {children}
                    </div>
                </a>
            ) : (
                <Link style={{background: background ? background : '#EE5259'}} to={link} onClick={() => onClick()} className={cn('custom-button', className)}>
                    <div className="custom-button__inner">
                        {children}
                    </div>
                </Link>
            )}
        </>
    )
}