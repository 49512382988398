import './PosterPage.scss';
import Header from "../../components/layouts/Header/Header";
import NewsCard from "../../components/NewsCard/NewsCard";
import {useEffect} from "react";
import axios from "axios";
import {useState} from "react";
import CustomButton from "../../components/CustomButton/CustomButton";

export default function PosterPage() {

    const [page, setPage] = useState(1);
    const [postersInView, setPostersInView] = useState([]);
    const [totalPostsLength, setTotalPostsLength] = useState([]);
    const [isAllPostersInView, setIsAllPostersInView] = useState(false);

    useEffect(() => {
        setIsAllPostersInView(postersInView.length >= totalPostsLength);
    }, [postersInView])

    useEffect(()=> {
        axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/posters?sort=id%3Adesc&pagination[page]=${page}`)
            .then(res => {
                if (page > 1) {
                    setPostersInView(postersInView => [...postersInView, ...res.data.data])
                } else {
                    setTotalPostsLength(res.data.meta.pagination.total)
                    setPostersInView(res.data.data)
                }
            })
    },[page])

    const postersList = postersInView.map((item) => {
        return (
            <NewsCard
                key={item.id}
                card={item.attributes}
                posters={true}
                id={item.id}
            />
        )
    });

    return (
        <div className={'poster-page'}>
            <Header/>
            <h1>Афиша Камчатки</h1>
            <div className="poster-page__grid">
                {postersList}
            </div>
            {!isAllPostersInView && (
                <CustomButton
                    background={'linear-gradient(92.17deg, #2B82ED 0.57%, #559CF3 100%)'}
                    onClick={() => setPage(page + 1)}
                >
                    Показать еще
                </CustomButton>
            )}
        </div>
    )
}