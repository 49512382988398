import './Modal.scss';
import cn from "classnames";

export const Modal = ({isOpen, onClose, children}) => {

    const onModalClick = ({target, currentTarget}) => {
        if (target === currentTarget) onClose()
    }

    return (
        <div onClick={onModalClick} className={cn('modal', {'modal_visible': isOpen})}>
            <div className={'modal__container'}>
                {children}
            </div>
        </div>
    )
}