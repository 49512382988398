import {useRef, useEffect, useState} from "react";
import {ReactComponent as PlayIcon} from "../../icons/play.svg";
import {ReactComponent as PauseIcon} from "../../icons/pause.svg";
import {ReactComponent as VolumeIcon} from "../../icons/volume.svg";
import {ReactComponent as MusicListIcon} from "../../icons/music-filter.svg";
import './otherRadio.scss';
import radioObj from '../../helpers/radioStations.json'
import cn from "classnames";
import ReactPlayer from "react-player";

const PlayButton = ({playing}) => {
    return playing ? <PauseIcon/> : <PlayIcon/>
};

const LoadingButton = () => {
    return (
        <div className="spinner">
            <div className="bounce1"/>
            <div className="bounce2"/>
            <div className="bounce3"/>
        </div>
    );
};

const errorPlay = "Ошибка, невозможно воспроизвести.";

export default function RadioDef() {

    const radioListRef = useRef();
    const [audioStreamUrl, setAudioStreamUrl] = useState();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [logo, setLogo] = useState(radioObj[0].logo);
    const [volume, setVolume] = useState(0.5);
    const [isVolumeVisible, setIsVolumeVisible] = useState(false);
    const [isListVisible, setIsListVisible] = useState(false);

    useEffect(() => {
        setAudioStreamUrl(radioObj[0].url)
    }, [])

    const switchRadio = (id) => {
        if (audioStreamUrl === radioObj[id].url) {
            setIsPlaying(!isPlaying);
            return;
        }
        setIsLoading(true);
        setIsPlaying(false);

        setLogo(radioObj[id].logo);

        setAudioStreamUrl(radioObj[id].url);

        setIsPlaying(true);
    };

    const onError = () => {
        setError(errorPlay);
        setTimeout(() => {
            setError('');
            // window.location.reload();
        }, 4000);
    }

    return (
        <div className="player">

            <ReactPlayer
                style={{display: 'none'}}
                url={audioStreamUrl}
                playing={isPlaying}
                volume={volume}
                playsinline={true}
                onReady={() => setIsLoading(false)}
                onError={() => onError()}
            />

            <div className="first">
                <div className="first__button-wrap">
                    <div className="radio" style={{backgroundImage: `url(..${logo})`}}/>

                    {!isLoading ? (
                        <div className="first__play-button" onClick={() => setIsPlaying(!isPlaying)}>
                            <PlayButton playing={isPlaying}/>
                        </div>
                    ) : (
                        <div className="button loading">
                            <LoadingButton/>
                        </div>
                    )}

                </div>

                <div className="first__invisible-block">Радио</div>

                <div
                    onClick={() => setIsVolumeVisible(!isVolumeVisible)}
                    onMouseEnter={() => setIsVolumeVisible(true)}
                    onMouseLeave={() => setIsVolumeVisible(false)}
                    className={cn("audio-volume", {"audio-volume_visible": isVolumeVisible})}
                >
                    <input
                        className={"audio-volume__input"}
                        type="range"
                        min="0"
                        max="1"
                        value={volume}
                        onChange={(e) => setVolume(e.target.value)}
                        step="0.01"
                    />

                    <div className="audio-volume__icon-wrap">
                        <VolumeIcon/>
                    </div>

                </div>
                <div className={'audio-volume__list-button'} onClick={() => setIsListVisible(!isListVisible)}>
                    <MusicListIcon/>
                </div>
            </div>

            {
                error !== "" ? (
                    <div className="error">
                        <span>{error}</span>
                    </div>
                ) : null
            }

            <div style={{
                minHeight: isListVisible ? `${radioListRef.current?.clientHeight}px` : '0px',
                height: isListVisible ? `${radioListRef.current?.clientHeight}px` : '0px',
                opacity: isListVisible ? '1' : '0'
            }} className="second">
                <div ref={radioListRef} className='radio-list'>
                    {radioObj.map(radio => {
                        return (
                            <div
                                key={radio.id}
                                className="radio-container"
                                onClick={() => switchRadio(radio.id)}
                                // onClick={radioList ? () => switchRadio(radio.id) : () => null}
                            >
                                <div className={'radio-container__img-wrap'}>
                                    <img src={radio.logo} alt="logo"/>
                                </div>
                                <div className={'radio-container__name-wrap'}>
                                    <span className="radio-name">
                                        {radio.name}
                                    </span>
                                </div>
                            </div>
                        );
                    })}

                </div>
            </div>
        </div>
    );
};
