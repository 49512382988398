import "./Currencies.scss";
import { regExCurrencies } from "../../../helpers/regex";
import { useEffect, useState } from "react";
import axios from "axios";
import { ReactComponent as Usd } from "../../../icons/usd.svg";
import { ReactComponent as Euro } from "../../../icons/euro.svg";
import { ReactComponent as Yen } from "../../../icons/yen.svg";
import { ReactComponent as Bitcoin } from "../../../icons/bitok.svg";
import { ReactComponent as ArrowIcon } from "../../../icons/arrow-blue.svg";
import cn from "classnames";

export default function Currencies() {
  const [currencies, setCurrencies] = useState();
  const [currentCurrenciesIndex, setCurrentCurrenciesIndex] = useState(0);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    Promise.all([
      axios(
        "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd/rub.json"
      ),
      axios(
        "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/eur/rub.json"
      ),
      axios(
        "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/cny/rub.json"
      ),
      axios(
        "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/jpy/rub.json"
      ),
      axios(
        "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/btc/usd.json"
      ),
    ]).then((res) => {
      setCurrencies([
        {
          name: "USD к Рублю",
          icon: <Usd />,
          value: res[0].data.rub,
        },
        {
          name: "EURO к Рублю",
          icon: <Euro />,
          value: res[1].data.rub,
        },
        {
          name: "CNY к Рублю",
          icon: <Yen />,
          value: res[2].data.rub,
        },
        {
          name: "JPY к Рублю",
          icon: <Yen />,
          value: res[3].data.rub,
        },
        {
          name: "BTC к USDT",
          icon: <Bitcoin />,
          value: res[4].data.usd,
        },
      ]);
    });
  }, []);

  if (!currencies) {
    return null;
  }

  return (
    <div className={"currencies"}>
      <div
        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
        className="currencies__wrap"
      >
        Валюта
        <span>
          <ArrowIcon />
        </span>
        <ul
          className={cn("currencies-header__dropdown", {
            "currencies-header__dropdown_visible": isDropdownVisible,
          })}
        >
          {currencies.map((item, index) => {
            return (
              <li
                className={cn({ shadow: currentCurrenciesIndex === index })}
                onClick={() => setCurrentCurrenciesIndex(index)}
                key={item.name}
              >
                {item.name}
              </li>
            );
          })}
        </ul>
      </div>

      <div className={"currencies__card"}>
        <div className="currencies__card-header">
          <div className="currencies__card-header-symbol">
            {currencies[currentCurrenciesIndex].icon}
          </div>
          {currencies[currentCurrenciesIndex].name}
        </div>
        <div className={"currencies__card-number"}>
          {currencies[currentCurrenciesIndex].value
            .toString()
            .match(regExCurrencies)}
          {currencies[currentCurrenciesIndex].name === "BTC к USDT" ? "$" : "₽"}
        </div>
      </div>

      {/*{ratesList}*/}
    </div>
  );
}
