import './ContactData.scss';

export default function ContactData({icon, text, specialLink}) {

    return (
        <div className={'contact-data'}>
            {icon}
            {specialLink ? <a href={specialLink}>{text}</a> : text}
        </div>
    )
}