import {useEffect, useState} from "react";
import {ReactComponent as Logo} from "../../icons/Vulcano-logo.svg";
import {ReactComponent as SendSvg} from "../../icons/Send.svg";
import {messageAPI} from "../../api/ChatGPT";
import './Chat.scss';
import cn from "classnames";

export const Chat = () => {

    const [textFromInput, setTextFromInput] = useState('');
    const [symbolsCounter, setSymbolsCounter] = useState(0);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);

    useEffect(() => {
        const checkKey = ({key}) => {
            if (key === 'Enter' && !loading) {
                sendMessage()
            }
        }

        document.addEventListener('keydown', checkKey)
        return () => document.removeEventListener('keydown', checkKey)
    }, [])

    const sendMessage = () => {
        setTextFromInput((inputValue) => {
            if (inputValue.length > 1) {
                setLoading(true)
                messageAPI
                    .getAnswer(inputValue)
                    .then((res) => {
                        setLoading(false)
                        setResponse(res.data)
                    })
                    .catch((error) => {
                        setLoading(false)
                        console.log(error)
                    });
            }

            return inputValue
        })
    };

    const onTextareaChange = (event) => {
        if (event.target.value.length <= 500) {
            setTextFromInput(event.target.value)
            setSymbolsCounter(event.target.value.length)
        }
    }

    return (
        <div className={'chat'}>
            <Logo className={'chat__logo'}/>

            <div className={cn('chat__textarea-wrap', {'chat__textarea-wrap_disabled': loading})}>
                {loading && <img className={'chat__loader'} src="/images/loader.gif" alt="Loader"/>}
                <textarea
                    value={textFromInput}
                    className={'chat__textarea'}
                    placeholder={'Напишите вопрос'}
                    onChange={(event) => onTextareaChange(event)}
                />

                <div className="chat__counter">{symbolsCounter} / 500</div>

                <div className={'chat__button'} onClick={sendMessage}>
                    <SendSvg/>
                </div>
            </div>
            <div className="chat__notice">*Наша нейросеть только обучается, задавайте более конкрентые запросы!</div>
            {response && <div className={'chat__response'}>{response}</div>}
        </div>
    )
}
