import './HeaderOptional.scss';
import {ReactComponent as SunIcon} from "../../../../icons/sun.svg";
import {ReactComponent as TelegramIcon} from "../../../../icons/telegram.svg";
import {ReactComponent as YouTubeIcon} from "../../../../icons/youtube.svg";
import {localStorageTheme} from "../../../../constants/localStorageIs";
import cn from "classnames";
import {useSetRecoilState} from "recoil";
import {isDarkModeState} from "../../../../pages/OurProjectsPage/darkModeState";
const socials = [
    {
        icon: <TelegramIcon/>,
        link: 'https://t.me/massmedianews'
    },
    {
        icon: <YouTubeIcon/>,
        link: 'https://www.youtube.com/channel/UC-8DrogDLvhgiZti88ygx1Q'
    }
];

export default function HeaderOptional({className}) {

    const setIsDarkMode = useSetRecoilState(isDarkModeState);
    const socialList = socials.map(item => {
        return (
            <a key={item.link} href={item.link} className={'header-optional__social-icon'}>
                {item.icon}
            </a>
        )
    })

    const changeDarkMode = () => {
        if (localStorage.getItem(localStorageTheme) === 'dark') {
            localStorage.removeItem(localStorageTheme);
            document.body.classList.remove('dark');
            setIsDarkMode(false)
        } else {
            localStorage.setItem(localStorageTheme, 'dark');
            document.body.classList.add('dark');
            setIsDarkMode(true)
        }
    }

    return (
        <div className={cn('header-optional', className)}>
            {socialList}
            <div onClick={changeDarkMode} className="header-optional__dark-mode-button">
                <SunIcon/>
                <div className="header-optional__dark-mode-text">
                    Темная тема
                </div>
            </div>
        </div>
    )
}