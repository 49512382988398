import {useEffect, useState} from "react";
const maxMobileWidth = 576;

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(()=> {
        if (window.innerWidth <= maxMobileWidth) {
            setIsMobile(true)
        }
        const changeInteract = ()=> setIsMobile(window.innerWidth <= maxMobileWidth);

        window.addEventListener('resize', changeInteract);

        return () => window.removeEventListener('resize', changeInteract);
    }, [])

    return isMobile
}