import './Radio.scss';
import cn from "classnames";
import RadioDef from "./RadioDef";

export default function Radio({className}) {
    return (
        <div className={cn('radio-wrap', className)}>
            <RadioDef/>
        </div>
    )
}