import './ButtonWithIcon.scss';
import {ReactComponent as ScrollIcon} from "../../icons/scroll.svg";
import cn from "classnames";

export default function ButtonWithIcon({children, className}) {
    return (
        <div className={cn("button-with-icon", className)}>
                <span>
                    {children}
                </span>
            <ScrollIcon/>
        </div>
    )
}