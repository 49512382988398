import './Weather.scss';
import {ReactComponent as WindIcon} from "../../../icons/wind.svg";
import {ReactComponent as DropletIcon} from "../../../icons/droplet.svg";
import {ReactComponent as TempIcon} from "../../../icons/temp.svg";
import {useEffect, useState} from "react";
import axios from "axios";
import {ReactComponent as ArrowIcon} from "../../../icons/arrow-blue.svg";
import cn from "classnames";

const places = [
    {
        name: 'П-Камчатский',
        json: 'https://weather.kamchatka1.tv/weather/pk',
    },
    {
        name: 'Елизово',
        json: 'https://weather.kamchatka1.tv/weather/elz',
    },
    {
        name: 'Вилючинск',
        json: 'https://weather.kamchatka1.tv/weather/vl',
    },
];

export default function Weather() {

    const [weather, setWeather] = useState();
    const [currentPlaceIndex, setCurrentPlaceIndex] = useState(0);
    const [day, setDay] = useState(0);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    useEffect(() => {
        axios.get(places[currentPlaceIndex].json)
            .then(({data}) => setWeather(JSON.parse(data).response[day]))
    }, [currentPlaceIndex, day])

    if (!weather) {
        return 'Загрузка...'
    }

    const getAverage = (max, min) => (max + min) / 2;

    const conditions = [
        {
            name: 'Температура',
            temperature: `${Math.floor(weather.temperature.air.avg.C)}°C`,
        },
        {
            name: 'Иконка погоды',
        },

        {
            icon: <DropletIcon/>,
            name: 'Влажность',
            number: `${weather.humidity.percent.avg} %`
        },
        {
            icon: <WindIcon/>,
            name: 'Ветер',
            number: `${Math.floor(getAverage(weather.wind.speed.max.m_s, weather.wind.speed.min.m_s))} м/с`
        },
        {
            icon: <TempIcon/>,
            name: 'Давление',
            number: `${Math.floor(getAverage(weather.pressure.mm_hg_atm.max, weather.pressure.mm_hg_atm.min))} мм рт. ст.`
        },
    ];

    const conditionsList = conditions.map((item, index) => {

        if (index === 0) {
            return (
                <div key={item.name} className={'weather-extended__condition'}>
                    <div className="weather-extended__condition-temp">
                        <div>{item.temperature}</div>
                    </div>
                </div>
            )
        } else if (index === 1) {
            return (
                <div key={item.name} className={'weather-extended__condition'}>
                    <img src={`/images/weatherIcons/${weather.icon}.svg`} alt="Current weather"/>
                </div>
            )
        } else {
            return (
                <div key={item.name} className={'weather-extended__condition'}>
                    {item.icon}
                    <div className="weather-extended__condition-number">
                        {item.name}
                        <div>{item.number}</div>
                    </div>
                </div>
            )
        }
    })

    return (
        <div className={'weather'}>
            <div className="weather__header weather-header">
                <div className="weather-header__text-wrap">

                    <div className="weather-header__days-wrap">
                        <div onClick={() => setDay(0)}
                             className={cn("weather-header__day", {"weather-header__day_underline": day === 0})}>Сегодня
                        </div>
                        <div onClick={() => setDay(1)}
                             className={cn("weather-header__day", {"weather-header__day_underline": day === 1})}>Завтра
                        </div>
                        <div onClick={() => setDay(2)}
                             className={cn("weather-header__day", {"weather-header__day_underline": day === 2})}>Послезавтра
                        </div>
                    </div>

                    <h3 onClick={() => setIsDropdownVisible(!isDropdownVisible)}>

                        {places[currentPlaceIndex].name}

                        <span className={'weather-header__choose-arrow'}>
                            <ArrowIcon/>

                            <ul className={cn('weather-header__dropdown', {'weather-header__dropdown_visible': isDropdownVisible})}>

                                {places.map((item, index) => {
                                    return (
                                        <li
                                            className={cn({'shadow': currentPlaceIndex === index})}
                                            onClick={() => setCurrentPlaceIndex(index)}
                                            key={item.name}
                                        >
                                            {item.name}
                                        </li>
                                    )
                                })}

                            </ul>
                        </span>
                    </h3>
                </div>

            </div>

            <div className="weather-header__description">{weather.description.full}</div>

            <div className="weather__extended weather-extended">
                {conditionsList}
            </div>
        </div>
    )
}