import axios from "axios";

const url = "https://vulcanoai.kamchatka1.tv/";

const message = axios.create({
  baseURL: url + "get_answer?text=",
});

export const messageAPI = {
  getAnswer: (messageFromInput) => {
    return message.get(messageFromInput).then((res) => res);
  },
};